.aboutmaincontainer{
    display: flex;
    padding:10px;
    justify-content: space-around;
    width:90%;
    margin: auto;

}
.aboutmaincontainer img{
    width:45%;
    margin-right: 20px;
}

@media screen and (max-width: 600px) {
    .aboutmaincontainer {
        display: flex;
        flex-direction: column; /* Stack content vertically */
        padding: 10px;
        width: 90%;
        text-align: justify;
        margin: auto;
    }
    
    .aboutmaincontainer img {
        width: 100%; /* Take full width */
        margin-bottom: 20px; /* Add space between image and text */
    }
    
    /* Additional styles for text */
    .aboutmaincontainer div {
        margin-bottom: 20px; /* Add space between sections */
    }
}