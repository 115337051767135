h5{
    font-size: 40px !important;
    color:rgb(255, 145, 0) !important;
}
.para{
    color:rgb(174, 0, 255) !important;
    font-size: 20px!important;
    font-weight: 800 !important;
}

@media only screen and (max-width: 600px) {
    h5{
        font-size: 20px !important;
        color:rgb(255, 145, 0) !important;
    }
    .para{
        color:rgb(174, 0, 255) !important;
        font-size: 12px!important;
        font-weight: 800 !important;
    }
  }