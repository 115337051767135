.firstcontainer{
    width:80Vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.firstcontainer p{
    width: 60%;
}
.firstcontainer img{
    height:350px;
    width:40%;
}
.secondcontainer{
    width:80Vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.secondcontainer p{
    width: 60%;
}
.secondcontainer img{
    width: 40%;
    height: 350px;
}

@media only screen and (max-width: 600px) {
    .firstcontainer, .secondcontainer {
      width: 90vw; /* Adjust width for smaller screens */
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items horizontally */
      text-align: center; /* Center text */
    }
    
    .firstcontainer img, .secondcontainer img {
      width: 80%; /* Adjust image width */
      height: auto; /* Maintain aspect ratio */
      margin-bottom: 20px; /* Add space between image and text */
    }
    
    .firstcontainer p, .secondcontainer p {
      width: 80%; /* Adjust paragraph width */
      text-align: justify;
      margin-top: 20px;
    }
  }