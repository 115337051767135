.smain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80vw;
    margin: auto;
}

.scard{
    width:24%;
    margin: 20px;
    transition: transform 1s linear;
}
.scard :hover{
    transform: scale(1.1);
}

.scard img{
    height:200px;
    width:100%;
    background-size: cover;
    border-radius: 10px;
}

.scardtext{
padding: 10px;
}

@media screen and (max-width:600px){
    .smain {
        display: flex;
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center align cards */
        width: 80vw; /* Adjust width for better responsiveness */
        margin: auto;
    }
    
    .scard {
        width: 100%; /* Cards take full width */
        margin: 20px 0; /* Adjust margin for spacing */
    }
    
    .scard img {
        width: 100%; /* Make images take full width */
        height: 250px; /* Auto adjust height */
        border-radius: 10px;
        margin: auto;
    }
    
    .scardtext {
        padding: 10px;
        text-align: justify; /* Center align text */
    }
}