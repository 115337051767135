.contactmaincontainer{
    display: flex;
    width:80%;
    margin: auto;
    justify-content: space-between;
}
.leftcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* background-color: blueviolet; */
    
}
.textcontainer {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding:10px;
    /* background-color: aqua; */
    margin: 5px;
}
.textcontainer span{
font-weight: 600;
font-size: larger;
}
.textcontainer svg{
    margin-right: 10px;
}
.map-container{
    width:50%;
    height: 400px;
}
.fct-map{
    height:100%;
    width:100%;
 }
 
 @media screen and (max-width: 600px) {
    .contactmaincontainer {
        display: flex;
        flex-direction: column; /* Stack content vertically */
        width: 90%; /* Adjust width for better responsiveness */
        margin: auto;
    }
    
    .leftcontainer {
        width: 100%; /* Take full width */
    }
    
    .textcontainer {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        margin: 10px 0; /* Adjust margin for spacing */
    }
    
    .textcontainer p {
        margin: 5px 0; /* Adjust margin for spacing */
    }
    
    .textcontainer span {
        font-weight: 600;
        font-size: larger;
    }
    
    .map-container {
        width: 100%; /* Take full width */
        height: 300px; /* Adjust height */
        margin-top: 20px; /* Add space between text and map */
    }
    
    .fct-map {
        height: 100%;
        width: 100%;
    }
 }