.navbarmain{
    background-color: rgb(40, 84, 150);
    width:100vw;
}
.navbarfirst{
width:80%;
margin:auto;
display: flex;
justify-content: space-between;
align-items: flex-end; 
color:white;
height: 50px;
box-sizing: border-box;
}
.navbarsecond{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:80vw;
    margin: auto
}
.navbarsecond img{
    height:120px;
    width:200px;
    background-size: cover;
}


.navbarlinkscontainer ul{
    display: flex;
}
.navbarlinkscontainer ul li{
    list-style-type: none;
    margin: 0 10px;
}

@media only screen and (max-width: 768px) {
    .navbarfirst,
    .navbarsecond {
      width: 100%;
    }
  .navbarfirst {
flex-direction: column;
justify-content: center;
align-items: center;
height: fit-content;
  }
    .navbarfirst p,
    .navbarlinkscontainer ul li {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  
    .navbarsecond {
      flex-direction: column; /* Stack links vertically */
      align-items: center; /* Center align links */
    }
  
    .navbarsecond img {
      margin-bottom: 20px; /* Add space between logo and links */
    }
  }